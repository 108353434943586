<template>
    <v-container fluid class="business-invitation">
        <div class="logo-div">
            <img src="@/assets/svg/logo-colored.svg" class="logo" />
        </div>
        <Loader v-if="loading" />
        <v-card class="info-card" v-else>
            <div class="banner-image">
                <v-img
                class="profile-image"
                :src="getOptimizedS3ImageURL(bannerLogo,
                  { width: 250, height: 250 })" alt="Profile"
              />
            </div>
            <div class="title">Join {{businessName}} as an {{roleName}}.</div>
            <v-form @submit="submitForm">
              <v-row class="field-row">
                  <v-col class="py-0 pl-0">
                    <label>First Name</label>
                      <BaseInput
                          background-color="#ffff"
                          class="mr-2 base-input-field"
                          solo
                          dense
                          flat
                          name="firstName"
                          v-model.trim="firstName"
                          :status="errors('firstName').length ? 'error' : 'normal'"
                          :error-messages="errors('firstName')"
                          @input="$v.firstName.$touch()"
                          @blur="$v.firstName.$touch()"
                          />
                  </v-col>
                  <v-col class="py-0 pr-0">
                    <label>Last Name</label>
                    <BaseInput
                        background-color="#ffff"
                        class="mr-2 base-input-field"
                        solo
                        dense
                        flat
                        name="lastName"
                        v-model.trim="lastName"
                        :status="errors('lastName').length ? 'error' : 'normal'"
                        :error-messages="errors('lastName')"
                        @input="$v.lastName.$touch()"
                        @blur="$v.lastName.$touch()"
                        />
                  </v-col>
              </v-row>
              <v-row class="field-row">
                  <v-col class="pa-0">
                    <label>Email</label>
                    <BaseInput
                        background-color="#ffff"
                        class="mr-2 base-input-field"
                        solo
                        dense
                        flat
                        name="email"
                        v-model.trim="email"
                        :status="errors('email').length ? 'error' : 'normal'"
                        :error-messages="errors('email')"
                        @input="$v.email.$touch()"
                        @blur="$v.email.$touch()"
                        disabled
                        />
                  </v-col>
              </v-row>
              <v-row class="field-row">
                  <v-col cols="8" class="pa-0">
                    <label>Phone Number</label>
                    <BaseInput
                        background-color="#ffff"
                        class="mr-2 base-input-field"
                        solo
                        dense
                        flat
                        name="phoneNumber"
                        v-mask="'###-###-####'"
                        v-model.trim="phoneNumber"
                        :status="errors('phoneNumber').length ? 'error' : 'normal'"
                        :error-messages="errors('phoneNumber')"
                        @input="$v.phoneNumber.$touch()"
                        @blur="$v.phoneNumber.$touch()"
                        />
                  </v-col>
              </v-row>
              <v-row class="field-row">
                  <v-col class="pa-0">
                    <label>Create your password</label>
                    <BaseInput
                        background-color="#ffff"
                        class="mr-2 base-input-field"
                        solo
                        dense
                        flat
                        name="password"
                        :type="passwordFieldType"
                        :appendIcon="(passwordFieldType === 'password') ? 'mdi-eye' : 'mdi-eye-off'"
                        :appendIconCallback="togglePasswordDisplay"
                        v-model.trim="password"
                        :status="errors('password').length ? 'error' : 'normal'"
                        :error-messages="errors('password')"
                        @input="$v.password.$touch()"
                        @blur="$v.password.$touch()"
                        />
                  </v-col>
              </v-row>
              <div class="btn-container">
                <div class="btn-div">
                  <v-btn
                  class="login-button btn-purple"
                  type="submit"
                  block>
                    Create Account
                  </v-btn>
                </div>
              </div>
            </v-form>
        </v-card>
    </v-container>
</template>

<script>
import _ from 'lodash';
import { mask } from 'vue-the-mask';
import {
  required, email, maxLength, minLength,
} from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
import { getOptimizedS3ImageURL } from '@/helpers/';
import bannerPlaceHolder from '@/assets/svg/banner-default.svg';
import BaseInput from '@/components/common/BaseInput';
import Loader from '@/components/common/Loader';

export default {
  name: 'BusinessInvitation',
  directives: {
    mask,
  },
  components: {
    BaseInput,
    Loader,
  },
  data() {
    return {
      loading: false,
      passwordFieldType: 'password',
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      password: '',
      businessName: null,
      roleName: null,
      bannerLogo: null,
    };
  },
  methods: {
    ...mapActions('user', ['getInviteeInfo', 'setBusinessInvitee', 'loginUser']),
    getOptimizedS3ImageURL,
    togglePasswordDisplay() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    async submitForm(e) {
      e.preventDefault();
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          confirmToken: this.$route.params.confirmToken,
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          number: this.phoneNumber,
          password: this.password,
        };
        const res = await this.setBusinessInvitee(payload);
        if (res.success) {
          const response = await this.loginUser({
            user: {
              email: this.email,
              password: this.password,
            },
            rememberMe: true,
          });
          if (response.success) {
            this.$router.push('/dashboard');
          }
        }
      }
    },
  },
  computed: {
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v[field].$dirty) return errors;
        switch (field) {
          case 'firstName':
            if (!this.$v.firstName.required) {
              errors.push('Please provide your first name.');
            }
            if (!this.$v.firstName.maxLength) {
              errors.push('First name must be less than 50 characters length.');
            }
            break;
          case 'lastName':
            if (!this.$v.lastName.required) {
              errors.push('Please provide your last name.');
            }
            if (!this.$v.lastName.maxLength) {
              errors.push('Last name must be less than 50 characters length.');
            }
            break;
          case 'email':
            if (!this.$v.email.email) {
              errors.push('Please provide a valid email address.');
            }
            if (!this.$v.email.required) {
              errors.push('Please provide your email address.');
            }
            break;
          case 'phoneNumber':
            if (!this.$v.phoneNumber.required) {
              errors.push('Please provide phone number.');
            }
            if (!this.$v.phoneNumber.minLength) {
              errors.push('Please provide valid phone number.');
            }
            break;
          case 'password':
            if (!this.$v.password.required) { errors.push('Please provide your password'); }
            break;
          default:
            break;
        }
        return errors;
      };
    },
  },
  validations: {
    firstName: {
      required,
      maxLength: maxLength(50),
    },
    lastName: {
      required,
      maxLength: maxLength(50),
    },
    email: {
      required,
      email,
    },
    phoneNumber: {
      required,
      minLength: minLength(12),
    },
    password: {
      required,
    },
  },
  async mounted() {
    this.loading = true;
    const res = await this.getInviteeInfo(this.$route.params.confirmToken);
    if (res.success) {
      const { user } = res;
      const role = _.get(user, 'roleName');
      const checkForMember = ['agency_member', 'member'].includes(role) ? 'member' : null;
      this.firstName = _.get(user, 'firstName');
      this.lastName = _.get(user, 'lastName');
      this.email = _.get(user, 'email');
      this.phoneNumber = _.get(user, 'number');
      this.businessName = _.get(user, 'businessName');
      this.roleName = ['agency_owner', 'client'].includes(role) ? 'owner' : checkForMember;
      this.bannerLogo = _.get(_.get(user, 'business'), 'bannerImage', '') || bannerPlaceHolder;
    } else {
      this.$router.push('/login');
    }
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.business-invitation {
  background-color: #ffffff;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .logo-div {
    margin-top: 8px;
    margin-bottom: 112px;
    .logo {
        width: 18.358rem;
        height: 5.61rem;
    }
  }
  .info-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #D1D1D1;
    box-sizing: border-box;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08) !important;
    border-radius: 8px;
    width: 467px;
    padding: 73px 36px;
    .banner-image {
        width: 72px;
        height: 72px;
        .profile-image {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
        }
    }
    .title {
      margin: 27px 0px 100px 0px;
      font-weight: 400;
      font-size: 30px !important;
      line-height: 36px;
      display: flex;
      align-items: center;
      text-align: center;
      color: $charcoalBlack;
    }
    .field-row {
      width: 100%;
      margin: 0px;
      .col {
        height: min-content;
        label {
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: $charcoalBlack;
        }
        .base-input-field {
          margin-top: 10px;
          ::v-deep .v-input__slot input {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #4F4F4F !important;
          }
          ::v-deep .normalState .v-input__slot {
            border: 1px solid $silver !important;
          }
        }
      }
    }
    .btn-container {
      display: flex;
      justify-content: center;
      .btn-div {
        .login-button {
          margin-top: 13px;
          width: 152px;
          ::v-deep .v-btn__content {
            font-family: $fontFamily1;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 19px;
          }
        }
      }
    }
  }
}
</style>
